
import { defineComponent } from "vue";
import variables from "@/router/api";
import { Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import "select2/dist/css/select2.css";
import "select2";
import DynamicInsuranceTerms from "@/components/modals/DynamicInsuranceTerms.vue";

export default defineComponent({
  name: "insurer-component",
  components: { Field, ErrorMessage, DynamicInsuranceTerms },
  props: {
    widgetClasses: String,
    underwriters: Array,
    selectedInsurer: String,
    displayDetails: Boolean,
    displayTermsButton: Boolean,
    displayTitle: Boolean,
  },
  data() {
    return {
      underwriter: "",
      details: "",
      name: "",
      logoUrl: "",
      termsAndConditions: [],
    };
  },
  created() {
    this.logoUrl = variables.UNDERWRITER_LOGO_ROUTE;
  },
  mounted() {
    const $underwriter = $("#underwriter");
    $underwriter.select2({
      placeholder: "Select an Underwriter...",
    });
    $underwriter.on("change", this.getLoadings);
  },
  beforeMount() {
    $("#underwriter").select2("destroy");
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/no-photo-available.png";
    },
    getLoadings(event) {
      this.underwriter = event.target.value;

      if (this.displayTermsButton) {
        // Get the selected option directly from the event.target
        const selectedOption = event.target.options[event.target.selectedIndex];

        if (selectedOption) {
          this.termsAndConditions = $(selectedOption).data("terms");
          this.details = this.termsAndConditions[0];
          this.name = event.target.options[event.target.selectedIndex].text;
          $("#termsButton").trigger("click");
          this.$emit("array-emitted", this.termsAndConditions);
        }
      }

      // console.log(event.target.options[event.target.selectedIndex].dataset.reference);
      this.$emit("underwriterId", event.target.value);
      this.$emit(
        "underwriterReference",
        event.target.options[event.target.selectedIndex].dataset.reference
      );
    },
  },
  computed: {
    mainSelectedInsurer() {
      return this.selectedInsurer;
    },
    mainDisplayDetails() {
      return this.displayDetails;
    },
  },
});
