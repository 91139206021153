
import { defineComponent } from "vue";

export default defineComponent({
  name: "dynamic-inusrance-terms-modal-component",
  components: {},
  props: {
    details: Object,
    name: String,
    termsAndConditions: Array,
  },
  data() {
    return {
      selectedTermIndex: 0,
    };
  },
});
